import "./src/global.css";
import IndexGlobal from "./src/components-page/index/IndexGlobal";
import React, { ReactNode } from "react";
import { SystemThemeProvider } from "./src/components/Theme";

export const wrapPageElement = ({ element }: { element: ReactNode }) => (
  <>
    <IndexGlobal />
    {element}
  </>
);

export const wrapRootElement = ({ element }: { element: ReactNode }) => (
  <SystemThemeProvider>{element}</SystemThemeProvider>
);
