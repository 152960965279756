import { GlobalMessageStackNode } from "../../components/MessageStack.code";
import { AjaxEffect } from "../../service/api";

/** 一些官网的全局副作用代码或组件 */
export default function IndexGlobal() {
  return (
    <>
      <AjaxEffect />
      <GlobalMessageStackNode maxStack={1} />
    </>
  );
}
