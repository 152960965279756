import { useEffect, useRef, useState } from "react";

/** 委托函数组件 */
export type EntrustFC<TEntrust> = (entrust: TEntrust) => React.ReactNode;

interface PopperEntrustProps {
  /** 展开 Popper */
  open: boolean;
  /** 设置展开 Popper */
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /** Popper 参考元素 */
  anchorEl: React.MutableRefObject<any>;
  /** 点击激活目标元素 */
  handleClickTarget(): void;
  /** 外部点击 */
  handleClickAway(): void;
}

/** Popper 操作委托 hook */
function usePopperEntrust(): PopperEntrustProps {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<any>(null!);

  function handleClickTarget() {
    setOpen((o) => !o);
  }

  function handleClickAway() {
    setOpen(false);
  }

  useEffect(() => {
    const blur = () => setOpen(false);

    window.addEventListener("blur", blur);
    return () => window.removeEventListener("blur", blur);
  }, []);

  return {
    open,
    setOpen,
    anchorEl,
    handleClickTarget,
    handleClickAway,
  };
}

/** Popper 操作委托 */
export const PopperEntrust: React.FC<{ children?: EntrustFC<PopperEntrustProps> }> = (props) => {
  const entrust = usePopperEntrust();
  return <>{props.children?.(entrust)}</>;
};

interface HeightEntrustProps {
  /** 元素实际高度 */
  height: number;
  /** 参考元素 s*/
  ref: React.MutableRefObject<HTMLLIElement>;
}

/** 获取元素实际高度委托的 hook */
function useHeightEntrust() {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLLIElement>(null!);

  useEffect(() => {
    const el = ref.current;

    // 保存源高度
    const sourceHeight = el.style.height;

    el.style.height = "auto";
    setHeight(el.offsetHeight);

    // 还原源高度
    el.style.height = sourceHeight;
  }, []);

  return { height, ref };
}

/** 获取元素实际高度委托 */
export const HeightEntrust: React.FC<{ children?: EntrustFC<HeightEntrustProps> }> = (props) => {
  const heightEntrust = useHeightEntrust();
  return <>{props.children?.(heightEntrust)}</>;
};
