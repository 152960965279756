import { ReactNode, createRef, useSyncExternalStore } from "react";
import { createSubscribeState } from "../lib/ExternalSubscribe";
import MessageStack, { MessageItem, MessageStackProps } from "./MessageStack";
import { v4 as uuid } from "uuid";
import { arraySet } from "../utils/utils";
import { isUndefined } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

/**
 *  创建全局 MessageStack 的状态，用于更方便的弹出消息
 */
function createGlobalMessageStackState() {
  const state = createSubscribeState([] as MessageItem[]);

  function remove(item: MessageItem) {
    state.set((o) => o.filter((v) => v.id !== item.id));
  }

  function push(item: MessageItem) {
    state.set((o) => [...o, item]);
  }

  function close(item: MessageItem) {
    state.set((o) => {
      const index = o.findIndex((v) => item.id === v.id);

      return arraySet(o, index, { ...o[index], open: false });
    });
  }

  const newState = { ...state, remove, push, close };

  return Object.assign(() => {
    const items = useSyncExternalStore(state.subscriber.syncExternalStore, state.get);
    return { items, ...newState } as const;
  }, newState);
}

/** 唯一一个全局 MessageStack 状态 */
export const useGlobalMessageStack = createGlobalMessageStackState();

interface MessateStackOptions {
  id?: string;
  manual?: boolean;
}

/** 弹出 toast 消息 */
export function messageStack(children: ReactNode, options: MessateStackOptions = {}) {
  const { id, manual } = options;
  const item: MessageItem = {
    id: id ?? uuid(),
    children,
    nodeRef: createRef(),
    open: true,
    manual: manual ?? false,
  };

  const stack = useGlobalMessageStack.get();
  const stackID = new Set(stack.map((v) => v.id));

  if (isUndefined(id) || !stackID.has(item.id)) {
    useGlobalMessageStack.push(item);
  } else {
    useGlobalMessageStack.set((old) =>
      arraySet(
        old,
        old.findIndex((v) => v.id === id),
        item
      )
    );
  }

  function update(children: ReactNode) {
    useGlobalMessageStack.set((old) => {
      const index = old.findIndex((v) => v.id === item.id);

      if (index !== -1) {
        return arraySet(old, index, { ...item, children });
      }
      return old;
    });
  }

  function close() {
    useGlobalMessageStack.close(item);
  }

  return { update, close };
}

type AsyncFn<TArg extends unknown[], TData> = (...args: TArg) => Promise<TData>;

interface MessageStackAsyncFnParams {
  label?: string;
}

/** 异步函数提示 */
export function messageStackAsyncFn<TArg extends unknown[], TData>(
  fn: AsyncFn<TArg, TData>,
  { label = "操作" }: MessageStackAsyncFnParams
): AsyncFn<TArg, TData> {
  return (...args) => {
    const msg = messageStack(
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: (theme) => theme.spacing(1),
        }}
      >
        <span>{label}中</span>
        <CircularProgress color="inherit" size={16} />
      </Box>,
      { manual: true }
    );

    const result = fn.call(undefined, ...args);

    result
      .then(
        () => messageStack(`${label}成功`),
        () => messageStack(`${label}失败`)
      )
      .finally(msg.close);

    return result;
  };
}

// interface MessageStackAsyncOptions {
//   label?: string;
// }

// /**
//  * 异步结果提示
//  *
//  * 注意不要使用到 ahooks 的 useRequest 中，useRequest 卸载后永远不会抛出成功或失败
//  */
// export function messageStackAsync<T>(state: Promise<T>, { label = "操作" }: MessageStackAsyncOptions) {
//   const msg = messageStack(
//     <Box
//       sx={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         gap: (theme) => theme.spacing(1),
//       }}
//     >
//       <span>{label}中</span>
//       <CircularProgress color="inherit" size={16} />
//     </Box>,
//     { manual: true }
//   );

//   state
//     .then(
//       () => messageStack(`${label}成功`),
//       () => messageStack(`${label}失败`)
//     )
//     .finally(msg.close);

//   return state;
// }

/** 全局 MessageStack 节点，这个节点每个页面只允许一次哦 */
export function GlobalMessageStackNode(props: Pick<MessageStackProps, "maxStack">) {
  const g = useGlobalMessageStack();

  return <MessageStack items={g.items} onDeleteItem={g.remove} onCloseItem={g.close} {...props} />;
}
