import { AxiosError, AxiosInstance } from "axios";
import { useEffect } from "react";
import { navigate } from "gatsby";
import { useTokenStore } from "../store/login";
import { messageStack } from "../components/MessageStack.code";
import { isUndefined } from "../utils/utils";

export interface ApiBase {
  status: number;
  msg: string;
}

export interface ApiFormat<D = unknown> extends ApiBase {
  data: D;
}

/** jwt 验证 */
export function useJWTAuth(ajax: AxiosInstance) {
  useEffect(() => {
    const id = ajax.interceptors.request.use((config) => {
      config.headers.Authorization = useTokenStore.getState().token;
      return config;
    });

    return () => void ajax.interceptors.request.eject(id);
  }, [ajax]);
}

/** 未登录重定向 */
export function useUnauthorizedRedirect(ajax: AxiosInstance) {
  useEffect(() => {
    const id = ajax.interceptors.response.use(
      (res) => res,
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          window.location.pathname !== "/" && navigate("/", { replace: true });

          useTokenStore.getState().logout();
        }
        return Promise.reject(error);
      }
    );

    return () => void ajax.interceptors.request.eject(id);
  }, [ajax]);
}

/** 错误提示 */
export function useErrorTip(ajax: AxiosInstance) {
  useEffect(() => {
    const id = ajax.interceptors.response.use(
      (res) => {
        if (res.data.status !== 200) {
          messageStack(res.data.msg ?? `服务器开小差了，请稍后再试。`);
          return Promise.reject({ ...res, response: res });
        }
        return res;
      },
      (error: AxiosError<{ msg?: string; status?: number }>) => {
        if (isUndefined(error.response)) {
          messageStack("空响应");
        } else if (error.response.status >= 500) {
          messageStack(`服务器错误。`);
        } else if (error.response.status > 401 || error.response.status === 400) {
          messageStack((error.response.data as any)?.msg ?? error.response.statusText ?? "客户端错误");
        } else if (error.response.status === 401) {
          void 0;
        } else if (error.response.status >= 200 && error.response.status <= 299) {
          void 0;
        } else {
          messageStack("意外的错误");
        }
        return Promise.reject(error);
      }
    );

    return () => {
      console.log("askdljlasjd");
      void ajax.interceptors.request.eject(id);
    };
  }, [ajax]);
}
