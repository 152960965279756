type DClass = boolean | string | undefined;

/** 类名叠加 */
export function classNamesJoin(...classes: (DClass | DClass[])[]) {
  return classes.flat(Infinity).filter(Boolean).join(" ");
}

export function getWindow() {
  if (typeof window !== "undefined") return window;
  else return null;
}
