import { create } from "zustand";
import Cookies from "js-cookie";
import { isString } from "../utils/utils";

export const TOKEN_KEY = "home_token";
/** 二维码标识 */
export const QR_CODE_ID = "74ed2e26-a048-4571-9e22-3baacfaca21f";

interface TokenState {
  /** 当前系统的 token，包含 JWT 前缀 */
  token?: string;

  /**
   * 设置一个新的 token，当参数 token 为 undefined 时为删除 token
   */
  setToken(token?: string): void;
  /**
   * 登出
   */
  logout(): void;
  /**
   * 是否登录
   */
  isLogin(): boolean;
}

export const useTokenStore = create<TokenState>((set, get) => ({
  token: Cookies.get(TOKEN_KEY) ?? undefined,
  setToken: (token?: string) => {
    set({ token });
    if (isString(token)) {
      Cookies.set(TOKEN_KEY, token, { expires: 365 * 2 });
    } else {
      Cookies.remove(TOKEN_KEY);
    }
  },
  logout: () => get().setToken(undefined),
  isLogin: () => isString(get().token),
}));

/** 创建登录二维码内容 */
export function createLoginQRCodeContent(uuid: string) {
  return JSON.stringify({
    id: QR_CODE_ID,
    uuid,
  });
}
