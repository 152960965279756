import axios from "axios";
import { ApiBase, ApiFormat, useErrorTip, useJWTAuth, useUnauthorizedRedirect } from "./model";
import { Fragment } from "react";
import { Language } from "../constants/constants";
import { OssModel } from "../lib/oss";

const ajax = axios.create({
  baseURL: process.env.GATSBY_API_URL,
});

/** 官网相关请求的副作用代码 */
export function AjaxEffect() {
  // 登录控制
  useJWTAuth(ajax);
  // 未登录重定向
  useUnauthorizedRedirect(ajax);
  // 错误提示
  useErrorTip(ajax);

  return <Fragment />;
}

export namespace PostUserSmsV3 {
  export interface Params {
    /**
     * 是否跳过腾讯人机验证，用于后端开发接口测试时使用；true跳过；false不跳过；如果滑板车官网不接入人机验证也需要传递该参数
     */
    is_official: boolean;
    /**
     * 用户手机号
     */
    mobile: string;
  }

  export interface Response {
    /** 验证码 */
    code: string; // "1024"
  }
}

/** 发送短信验证码 https://huabanche.apifox.cn/api-77190398 */
export function apiPostUserSmsV3(data: PostUserSmsV3.Params) {
  return ajax.post<ApiFormat<PostUserSmsV3.Response>>("/user/sms/v3", data);
}

export namespace PostUserLoginMobile {
  export interface Params {
    /**
     * 验证码
     */
    code: string;
    /**
     * 手机号
     */
    mobile: string;
    /**
     * 昵称，非必需
     */
    nickname?: string;
  }

  export type Response = ApiFormat<Data> & {
    /** 这个字段从获取用户信息接口拿，不从这里拿 */
    user_info: unknown;
  };

  /**
   * 响应数据
   */
  export interface Data {
    compensated_for_ios: boolean;
    compensated_for_miniprogram: boolean;
    /**
     * 是否是新用户
     */
    is_new_user: number;
    last_choosing_subject: LastChoosingSubject;
    /**
     * JWT Token
     */
    token: string;
    /**
     * 用户昵称，默认等于手机号
     */
    username: string;
  }

  export interface LastChoosingSubject {
    subject: string;
    volume: number;
  }
}

/** 手机验证码登录 https://huabanche.apifox.cn/api-77230418 */
export function apiPostUserLoginMobile(data: PostUserLoginMobile.Params) {
  return ajax.post<PostUserLoginMobile.Response>("/user/login/mobile", data);
}

export namespace PostUserLoginPassword {
  export interface Params {
    /**
     * 用户的密码
     */
    password: string;
    /**
     * 填用户手机号
     */
    username: string;
  }

  export type Response = PostUserLoginMobile.Response;
}

/** 密码登录 https://huabanche.apifox.cn/api-77114330 */
export function apiPostUserLoginPassword(data: PostUserLoginPassword.Params) {
  return ajax.post<PostUserLoginPassword.Response>("/user/login/password", data);
}

export namespace GetUserInfoV2 {
  export interface Response extends ApiBase {
    user_info: UserInfo;
  }

  /**
   * 用户信息
   */
  export interface UserInfo {
    accruing_attendance_days: number;
    accruing_recite_seconds: number;
    /**
     * 成就数
     */
    achievement_count: number;
    /**
     * 用户头像
     */
    avatar: string;
    has_consumption: boolean;
    /**
     * 用户ID
     */
    id: number;
    /**
     * 拍照识别过期时间
     */
    img2world_exp_time: number;
    /**
     * 最近一次背诵的时间
     */
    last_recite_time: number;
    /**
     * 用户昵称
     */
    nickname: string;
    probation: number;
    /**
     * 背诵记录条数
     */
    record_count: number;
    star: number;
    /**
     * 用户过期时间
     */
    vip_exp_time: number;
    /**
     * 用户类型
     */
    vip_type: number;
  }
}

/** 获取用户信息 https://huabanche.apifox.cn/api-78559709 */
export function apiGetUserInfoV2() {
  return ajax.get<GetUserInfoV2.Response>("/user/info/v2");
}

export namespace UserLoginQR {
  export enum Status {
    /** 未扫码 */
    NotScanned = 1,
    /** 已扫码，待授权 */
    Unauthorized = 2,
    /** 已授权，授权成功 */
    Authorized = 3,
    /** 二维码失效 */
    Fail = 4,
  }
}

/** 扫码登录-Web-获取uuid https://huabanche.apifox.cn/api-78037226 */
export function apiGetUserLoginQR() {
  return ajax.get<ApiFormat<{ uuid: string }>>("/user/login/qr");
}

namespace PostUserLoginQR {
  export interface Params {
    uuid: string;
  }

  export type Response = ApiFormat<{
    status: UserLoginQR.Status.NotScanned | UserLoginQR.Status.Unauthorized | UserLoginQR.Status.Fail;
    prompt: string;
  }>;

  export interface ResponseSuccess extends ApiBase {
    /**
     * 响应数据
     */
    data: Data;
    /**
     * 用户信息 这个字段从获取用户信息接口拿
     */
    user_info: unknown;
  }

  /**
   * 响应数据
   */
  export interface Data {
    /**
     * 是否是新用户
     */
    is_new_user: null;
    /**
     * 扫码提示语
     */
    prompt: string;
    /**
     * 扫码状态
     */
    status: UserLoginQR.Status.Authorized;
    /**
     * JWT
     */
    token: string;
    /**
     * 用户名
     */
    username: string;
  }
}

/** 扫码登录-Web-轮询扫码状态 https://huabanche.apifox.cn/api-78039393 */
export function apiPostUserLoginQR(data: PostUserLoginQR.Params) {
  return ajax.post<PostUserLoginQR.Response | PostUserLoginQR.ResponseSuccess>("/user/login/qr", data);
}

/** PC-在目录下创建文章 https://huabanche.apifox.cn/api-77271484 */
export function apiPostEvalPcUserChapterChapters(data: { language: Language }) {
  return ajax.post<ApiFormat<{ chapter_id: number }>>("/eval/pc/user-chapter/chapters", data);
}

/** PC-在目录下删除文章 https://huabanche.apifox.cn/api-77551878 */
export function apiDeleteEvalPcUserChapterChaptersChapterID(chapter_id: number | string) {
  return ajax.delete(`/eval/pc/user-chapter/chapters/${chapter_id}`);
}

export namespace PostEvalPcUserChapterDirs {
  export interface Params {
    dir_id: string | number;
    chapter_id: number;
  }
}

/** PC-在目录间移动文章 https://huabanche.apifox.cn/api-77613124 */
export function apiPostEvalPcUserChapterDirsDirId({ dir_id, chapter_id }: PostEvalPcUserChapterDirs.Params) {
  return ajax.post(`/eval/pc/user-chapter/dirs/${dir_id}`, { chapter_id });
}

export namespace GetEvalPcUserChapterDirs {
  export interface Params {
    /**
     * 是否需要文章；true表示需要，则返回目录和目录对应的文章；false表示不需要，则只返回目录
     */
    need_chapters: string;
    /**
     * 在 need_chapters 为true时使用，是否需要文章的背诵次数；true表示需要；false表示不需要
     */
    need_recite_number: string;
  }

  export interface ParamsNeedChapters extends Params {
    need_chapters: "true";
  }

  export interface Response extends ApiBase {
    /**
     * 目录数据
     */
    dirs: Dir[];
    user_info: UserInfo;
  }

  export interface Dir {
    /**
     * 目录包含的文章数
     */
    chapter_count: number;
    /**
     * 目录包含的文章数据
     */
    chapters: Chapter[];
    /**
     * 目录id
     */
    id: number;
    /**
     * 目录名
     */
    name: string;
    /**
     * 提示语，只有目录为0才有；即只有“未分类文件”才有提示
     */
    prompt?: string;
  }

  export interface Chapter {
    /**
     * 文章id
     */
    id: number;
    /**
     * 是否是系统文章
     */
    is_sys: boolean;
    /**
     * 语言
     */
    language: number;
    /**
     * 背诵次数
     */
    recite_amount: number;
    /**
     * 句子总数
     */
    sentence_total: number;
    /**
     * 文章概要
     */
    summary: string;
    /**
     * 文章标题
     */
    title: string;
  }

  export interface UserInfo {
    has_consumption: boolean;
    probation: number;
    status: number;
  }
}

/** PC-获取用户自定义的所有目录或目录+文章  https://huabanche.apifox.cn/api-77590223 */
export function apiGetEvalPcUserChapterDirs(params: GetEvalPcUserChapterDirs.ParamsNeedChapters) {
  return ajax.get<GetEvalPcUserChapterDirs.Response>(`/eval/pc/user-chapter/dirs`, { params });
}

/** PC-创建目录 https://huabanche.apifox.cn/api-77581370 */
export function apiPostEvalPcUserChapterDirs(data: { dir_name: string }) {
  return ajax.post(`/eval/pc/user-chapter/dirs`, data);
}

/** PC-删除目录 https://huabanche.apifox.cn/api-77580606 */
export function apiDeleteEvalPcUserChapterDirsDirID({ dir_id }: { dir_id: number }) {
  return ajax.delete(`/eval/pc/user-chapter/dirs/${dir_id}`);
}

namespace GetEvalUserChapter {
  export interface Params {
    chapter_id: number;
  }

  export interface Response extends ApiBase {
    /**
     * 响应数据
     */
    data: Data;
  }

  /**
   * 响应数据
   */
  export interface Data {
    /**
     * 课文具体内容
     */
    details: Array<string[]>;
    /**
     * 课文ID
     */
    id: number;
    /**
     * 是否是系统课文，true是；false不是
     */
    is_sys: boolean;
    /**
     * 课文语言，1是中文；2是英文；3是中英文
     */
    language: Language;
    /**
     * 课文句子数
     */
    sentence_total: number;
    /**
     * 课文概要
     */
    summary: string;
    /**
     * 课文标题
     */
    title: string;
    /**
     * 最近一次更新的时间，时间戳，毫秒级
     */
    update_time: number;
  }
}

/** APP&PC-获取单篇文章详细内容 https://huabanche.apifox.cn/api-77274415 */
export function apiGetEvalUserChapter({ chapter_id }: GetEvalUserChapter.Params) {
  return ajax.get<GetEvalUserChapter.Response>(`/eval/UserChapter/${chapter_id}`);
}

namespace GetEvalUserChapterResources {
  export interface Params {
    chapter_id: number;
  }

  export interface Response extends ApiBase {
    /**
     * 响应数据
     */
    data: Data;
  }

  /**
   * 响应数据
   */
  export interface Data {
    /**
     * 图片OSS URI
     */
    pictures_uri: string[];
    /**
     * 音频OSS URI
     */
    recording_uri: string;
    /**
     * OSS URI前缀，后端只存path部分，前端自行拼接下
     */
    uri_prefix: string;
  }
}

/** https://huabanche.apifox.cn/api-77114138 获取用户图片及音频资源 */
export function apiGetEvalUserChapterResources({ chapter_id }: GetEvalUserChapterResources.Params) {
  return ajax.get<GetEvalUserChapterResources.Response>(`/eval/user-chapter/resources/${chapter_id}`);
}

export namespace PutEvalPcUserChapterChapters {
  export interface Params {
    params: {
      /** 文章 ID */
      chapter_id: number;
    };
    data: Data;
  }

  export interface Data {
    /**
     * 课文内容，传递数组的字符串形式，数组中包含一个元素，字符串格式，为用户输入的所有文本内容。 详情见示例。
     */
    details: string;
    /**
     * 课文语言，1表示中文；2表示英文；3表示中英文
     */
    language: number;
    /**
     * 图片OSS URI，只传path部分哦
     */
    pictures: string[];
    /**
     * 录音OSS URI，只传path部分哦
     */
    recording: string;
    /**
     * 课文标题
     */
    title: string;
  }
}

/** https://huabanche.apifox.cn/api-77867975 PC-在目录下更新文章 */
export function apiPutEvalPcUserChapterChapters({ params, data }: PutEvalPcUserChapterChapters.Params) {
  const { chapter_id } = params;
  return ajax.put(`/eval/pc/user-chapter/chapters/${chapter_id}`, data, {
    headers: {
      [`X-Huabanche-Import-From`]: "web",
    },
  });
}

namespace GetEvalAliOssSignatures {
  export interface Params {
    /**
     * 课文的语言；1是中文；2是英文；3是中英文
     */
    language: Language;
    /**
     * 要上传的资源类型；picture是图片资源；recording是用户的录音
     */
    resource_type: "picture" | "recording";
  }
}

/** 获取服务端直传签名（不带回调） 获取上传图片或音频的 oss token https://huabanche.apifox.cn/api-77799734  */
export function apiGetEvalAliOssSignatures(params: GetEvalAliOssSignatures.Params) {
  return ajax.get<ApiFormat<OssModel.ServerDirect>>("/eval/ali/oss/signatures", { params });
}
