import { createTheme } from "@mui/material";
import { Theme as MuiTheme } from "@mui/material/styles";
import "@emotion/react";
import { ThemeProvider } from "@emotion/react";

const theme = createTheme();

declare module "@emotion/react" {
  export interface Theme extends MuiTheme {}
}

/** 让 emotion 拥有 mui 的主题 */
export function SystemThemeProvider(props: { children?: React.ReactNode }) {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
