exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-import-edit-tsx": () => import("./../../../src/pages/import/edit.tsx" /* webpackChunkName: "component---src-pages-import-edit-tsx" */),
  "component---src-pages-import-file-tsx": () => import("./../../../src/pages/import/file.tsx" /* webpackChunkName: "component---src-pages-import-file-tsx" */),
  "component---src-pages-import-index-tsx": () => import("./../../../src/pages/import/index.tsx" /* webpackChunkName: "component---src-pages-import-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-self-reg-con-index-tsx": () => import("./../../../src/pages/self-reg-con/index.tsx" /* webpackChunkName: "component---src-pages-self-reg-con-index-tsx" */),
  "component---src-pages-self-reg-con-reg-temp-tsx": () => import("./../../../src/pages/self-reg-con/regTemp.tsx" /* webpackChunkName: "component---src-pages-self-reg-con-reg-temp-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */)
}

