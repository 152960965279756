import styled from "@emotion/styled";
import { ComponentPropsWithoutRef, ReactNode } from "react";

interface MessageBarProps extends ComponentPropsWithoutRef<"div"> {
  /** 消息内容 */
  children?: ReactNode;
  /** 点击事件 */
  onClick?(): void;
}

/** 消息条 */
export function MessageBar({ ...props }: MessageBarProps) {
  return <SRoot {...props} />;
}

const SRoot = styled.div`
  width: 640px;
  line-height: 32px;
  background: #3e73f8;
  border-radius: 4px;
  text-align: center;

  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;
