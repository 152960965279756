import { useEffect, useState } from "react";
import { getWindow } from "./compute";

/** 聚焦检查 */
export function useFocus<E extends Element | Window>(e: E) {
  const [focus, setFocus] = useState(e instanceof Window ? document.hasFocus() : document.activeElement === e);

  useEffect(() => {
    const onFocus = () => setFocus(true);
    const onBlur = () => setFocus(false);

    e.addEventListener("focus", onFocus);
    e.addEventListener("blur", onBlur);

    return () => {
      e.removeEventListener("focus", onFocus);
      e.removeEventListener("blur", onBlur);
    };
  }, [e]);

  return focus;
}

/** 获取视口滚动 */
export function useViewportScroll() {
  const [scroll, setScroll] = useState({ x: getWindow()?.scrollX ?? 0, y: getWindow()?.scrollY ?? 0 });

  useEffect(() => {
    const handle = () => {
      setScroll({ x: window.scrollX, y: window.scrollY });
    };

    handle();
    window.addEventListener("scroll", handle);

    return () => window.removeEventListener("scroll", handle);
  }, []);

  return [scroll];
}
