import { useSyncExternalStore } from "react";

export function createSubscribe() {
  const subscribers = new Map<() => void, () => void>();

  /** 分发订阅 */
  function dispatch() {
    subscribers.forEach((fn) => fn());
  }

  /** 订阅函数 */
  function subscribe(fn: () => void) {
    subscribers.set(fn, fn);
  }

  /** 取消订阅函数 */
  function unSubscribe(fn: () => void) {
    subscribers.delete(fn);
  }

  /** react 订阅 */
  const syncExternalStore: Parameters<typeof useSyncExternalStore>["0"] = (onStoreChange) => {
    subscribe(onStoreChange);
    return () => unSubscribe(onStoreChange);
  };

  return { dispatch, subscribe, unSubscribe, syncExternalStore };
}

/**
 * 创建订阅状态
 *
 * @param initState 初始状态
 * @returns
 */
export function createSubscribeState<T>(initState: T) {
  let state = initState;
  const subscriber = createSubscribe();

  function get() {
    return state;
  }

  function staticSet(valueOrSetValue: T | ((old: T) => T)) {
    const newState = valueOrSetValue instanceof Function ? valueOrSetValue(state) : valueOrSetValue;
    state = newState;
  }

  function set(valueOrSetValue: T | ((old: T) => T), force = false) {
    const newState = valueOrSetValue instanceof Function ? valueOrSetValue(state) : valueOrSetValue;
    const oldState = state;
    state = newState;

    if (oldState !== newState || force) {
      subscriber.dispatch();
    }
  }

  return {
    subscriber,
    get,
    staticSet,
    set,
  };
}
