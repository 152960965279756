import styled from "@emotion/styled";
import { MessageBar } from "./MessageBar";
import { ReactNode, useEffect, useRef, useState, useMemo } from "react";
import { Transition, TransitionStatus } from "react-transition-group";
import "./MessageStack.css";
import { EntrustFC, HeightEntrust } from "./Entrust";
// import { constStyles } from "../constants/styles";
import { isNumber, isUndefined } from "../utils/utils";
import { useFocus } from "../utils/hooks";

const durationTransition = 150;
const duration = 2500;

interface GetTransitionStylesParams {
  /** 元素的高度 */
  height: number;
}

/** 过渡状态 */
function getTransitionStyles({
  height,
}: GetTransitionStylesParams): Partial<Record<TransitionStatus, React.CSSProperties>> {
  return {
    entering: { opacity: 1, height },
    entered: { opacity: 1, height },
    exiting: { opacity: 0, height: 0 },
    exited: { opacity: 0, height: 0 },
  };
}

/** 需要过渡的样式 */
function getTransition(state: TransitionStatus) {
  const enter = `opacity ${durationTransition}ms ease-in-out`;
  const exit = enter + `, height ${durationTransition}ms ease-in-out`;

  return state === "entered" || state === "entering" ? enter : exit;
}

export interface MessageItem {
  id: string | number;
  children?: ReactNode;
  nodeRef: React.RefObject<HTMLLIElement>;
  open: boolean;
  manual: boolean;
}

export interface MessageStackProps {
  maxStack?: number;

  /** 消息项 */
  items?: MessageItem[];

  /** 删除某一项 */
  onDeleteItem?(item: MessageItem): void;

  /** 关闭某一项 */
  onCloseItem?(item: MessageItem): void;
}

/** 消息栈 */
export default function MessageStack({ maxStack = 1, items = [], ...props }: MessageStackProps) {
  const manualStack = useMemo(() => items.filter((v) => v.manual), [items]);
  const autoStack = useMemo(() => items.filter((v) => !v.manual), [items]);

  // 超过最大栈删除
  useEffect(() => {
    if (autoStack.length > maxStack && autoStack[0].open === true) {
      props.onCloseItem?.call(undefined, autoStack[0]);
    }
  }, [maxStack, props.onCloseItem, autoStack]);

  return (
    <SRoot>
      <SList>
        {items
          .flat(1)
          .slice(0, manualStack.length + maxStack)
          .map((v) => (
            <Transition
              key={v.id}
              nodeRef={v.nodeRef}
              timeout={durationTransition}
              in={v.open}
              onExited={() => props.onDeleteItem?.(v)}
              appear
            >
              {(state) => {
                return (
                  <HeightEntrust>
                    {(heightEntrust) => (
                      <TimeoutEntrust
                        key={v.id}
                        time={duration}
                        disabled={v.manual}
                        onTimeout={() => props.onCloseItem?.(v)}
                      >
                        {(timeoutEntrust) => (
                          <SItem
                            ref={(el) => {
                              v.nodeRef = { current: el };
                              heightEntrust.ref.current = el!;
                            }}
                            style={{
                              transition: getTransition(state),
                              opacity: 0,
                              ...getTransitionStyles({ height: heightEntrust.height })[state],
                            }}
                            onMouseEnter={() => timeoutEntrust.setLock(true)}
                            onMouseLeave={() => timeoutEntrust.setLock(false)}
                          >
                            <MessageBar style={{ marginBottom: "24px" }}>{v.children}</MessageBar>
                          </SItem>
                        )}
                      </TimeoutEntrust>
                    )}
                  </HeightEntrust>
                );
              }}
            </Transition>
          ))}
      </SList>
    </SRoot>
  );
}

const SRoot = styled.div`
  position: fixed;
  top: 48px;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.tooltip};

  display: flex;
  justify-content: center;
  margin: auto;

  pointer-events: none;
`;

const SList = styled.ol`
  padding-left: 0;
  margin: 0;

  pointer-events: none;
`;

const SItem = styled.li`
  list-style: none;
  overflow: hidden;

  & > * {
    pointer-events: initial;
  }
`;

interface TimeoutEntrustProps {
  /** 超时时间 */
  time: number;
  /** 超时事件 */
  onTimeout?(): void;
  children?: EntrustFC<{
    /** 锁定计时 */
    setLock: React.Dispatch<React.SetStateAction<boolean>>;
  }>;
  disabled?: boolean;
}

/** 超时委托 */
function TimeoutEntrust({ time, onTimeout, children, disabled }: TimeoutEntrustProps) {
  const [timerID, setTimerID] = useState<number>();
  /** 是否计时结束 */
  const [isEnd, setIsEnd] = useState(false);
  /** 锁定计时 */
  const [lock, setLock] = useState(false);
  const focus = useFocus(window);

  const onTimeoutRef = useRef(onTimeout);
  onTimeoutRef.current = onTimeout;

  /** 清除定时 */
  const clear = useMemo(
    () => () =>
      setTimerID((id) => {
        isNumber(id) && window.clearTimeout(id);
        return undefined;
      }),
    []
  );

  /** 超时 */
  const timeout = useMemo(
    () => () => {
      setIsEnd(true);
      onTimeoutRef.current?.();
      clear();
    },
    [clear]
  );

  /** 开始计时 */
  const start = useMemo(
    () => () => {
      if (!isEnd) {
        const timer = window.setTimeout(timeout, time);
        setTimerID(timer);
      }
    },
    [isEnd, timeout, time]
  );

  // 计时控制
  useEffect(() => {
    if (disabled) return;

    // 【锁定或失焦】并且有定时器时 清除定时
    if ((lock || !focus) && isNumber(timerID)) return clear();

    // 没锁定、聚焦且没定时器时
    if (!lock && focus && isUndefined(timerID)) return start();
  }, [disabled, clear, focus, lock, start, timerID]);

  return <>{children?.({ setLock })}</>;
}
